import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { SliderProducts } from "../../data/products";
import { Navigation, Pagination } from "swiper";
import css from "./Slider.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export const Slider = () => (
  <div className="s-container">
    <Swiper
      breakpoints={{
        640: {
          slidesPerView: 3,
        },
        0: {
          slidesPerView: 1,
        },
      }}
      modules={[Pagination, Navigation]}
      className="mySwiper"
      loopFillGroupWithBlank={true}
      navigation={true}
      spaceBetween={40}
      slidesPerView={3}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      slidesPerGroup={1}
      loop={true}
    >
      {SliderProducts.map((slide, i) => (
        <SwiperSlide>
          <div className="left-s">
            <div className="name">
              <span>{slide.name}</span>
              <span>{slide.detail}</span>
            </div>
            <span>{slide.price}$</span>
            <div>Shop Now</div>
          </div>
          <img src={slide.img} alt="product" className="img-p"></img>
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
);
