import React from "react";
import css from "./Hero.module.css";
import HeroImg from "../../assets/hero.png";
import { RiShoppingBagFill } from "react-icons/ri";
import { BsArrowRight } from "react-icons/bs";
import { motion } from "framer-motion";

export const Hero = () => {
  const transition = { duration: 3, type: "spring" };
  return (
    <div className={css.container}>
      {/* sides code */}
      <div className={css.h_sides}>
        <div className={css.text1}>
          <span>Skin Protection Cream</span>
        </div>
        <div className={css.text2}>
          <span>Trendy Collections</span>
          <span>{""}everythig is great, please suggest what to do</span>
        </div>
      </div>

      {/*middle section code*/}
      <div className={css.wrapper}>
        {/* Motion view for bluccircle*/}
        <motion.div
          initial={{ bottom: "2rem" }}
          whileInView={{ bottom: "0rem" }}
          transition={transition}
          className={css.blueCircle}
        ></motion.div>
        <motion.img
          initial={{ bottom: "-2rem" }}
          whileInView={{ bottom: "0rem" }}
          transition={transition}
          src={HeroImg}
          alt=""
          width={600}
        ></motion.img>
        {/* Motion view for bluccircle*/}
        <motion.div
          initial={{ right: "4%" }}
          whileInView={{ right: "2%" }}
          transition={transition}
          className={css.cart2}
        >
          <RiShoppingBagFill />
          <div className={css.signup}>
            <span>Best Signup Offers</span>
            <div>
              <BsArrowRight />
            </div>
          </div>
        </motion.div>
      </div>
      {/*right section code*/}
      <div className={css.h_sides}>
        <div className={css.traffic}>
          <span>1.5k</span>
          <span>Monthly Users</span>
        </div>

        <div className={css.customers}>
          <span>10k</span>
          <span>Happy Customers</span>
        </div>
      </div>
    </div>
  );
};
