import "./App.css";
import { Header } from "./components/header/Header";
import { Hero } from "./components/hero/Hero";
import { Products } from "./components/Products/Products";
import { Slider } from "./components/Slider/Slider";
import {Virtual} from "./components/Virtual/Virtual";
import {Testimonial} from './components/Testimonials/Testimonial';
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <Header/>
      <Hero/>
      <Slider/>
      <Virtual/>
      <Products/>
      <Testimonial/>
      <Footer/>
    </div>
  );
}

export default App;
